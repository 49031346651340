<template>
  <main class="container">
    <div class="header h3 mt-2">
          Ingreso de Facturas
        <router-link to="/purchases/create"  class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Cargar Nueva Factura</router-link>
    </div>

    <!-- DETAILS MODAL -->
    <b-modal id="modal-1" title="Detalles de Factura" class="p-3" centered v-model="showDetailsModal" hide-footer>
        <div class="text-dark">
            <table class="w-100 mb-3">
                <tr>
                    <td class="text-center">Número</td>
                    <td class="text-center">Proveedor</td>
                    <td class="text-center">Fecha:</td>

                </tr>
                <tr>
                    <td class="text-center fw-bold">{{selectedItem.id}}</td>
                    <td class="text-center fw-bold">{{selectedItem.provider}}</td>
                    <td class="text-center fw-bold">{{selectedItem.invoiceDate}}</td>
                </tr>
            </table>

            <hr>
            <div class="row text-dark" v-for="product in selectedItem.products" :key="product.id">
                <div class="col text-dark" v-for="(n,i) in Object.keys(product)" :key="'col'+n">
                    <b class="text-capitalize text-green-2">{{Object.keys(product)[i]}}</b> <br>
                    {{product[n]}}
                </div>
            </div>

        </div>
    </b-modal>

    <!-- modal puchase details -->
    <b-modal id="modal-2" title="Lista de productos" class="p-3" centered v-model="showDetailsModalDetails" hide-footer>
        <div class="text-dark">
            <table class="w-100 mb-3">
                <tr>
                    <td class="text-center">Número</td>
                    <td class="text-center">Nombre producto</td>
                    <td class="text-center">Precio:</td>
                    <td class="text-center">Cantidad:</td>
                    <td class="text-center">Acciones:</td>

                </tr>
                <tr v-for="it of selectedItemDetails" :key="it.id">
                    <td class="text-center fw-bold">{{it.id}}</td>
                    <td class="text-center fw-bold">{{it.name}}</td>
                    <td class="text-center fw-bold">{{it.price}}</td>
                    <td class="text-center fw-bold">{{it.quantity}}</td>
                    <td class="text-center fw-bold">
                        <button @click="gotoProductStockModal(it)" class="btn-1 ms-2" style="min-width:50px;margin-top:15px;"><i class="fas fa-file"></i></button>
                    </td>
                </tr>
            </table>

            <hr>
            <!-- <div class="row text-dark" v-for="details in selectedItemDetails" :key="details.id">
                <div class="col text-dark" v-for="n in details" :key="'col'+n">
                    <b class="text-capitalize text-green-2">{{Object.keys(product)[i]}}</b> <br>
                    {{product[n]}}
                </div>
            </div> -->

        </div>
    </b-modal>


    <!-- modal products -->
    <b-modal id="modal-3" title="Lista de campos x producto" class="p-3" centered v-model="showProductsModal" hide-footer>
        <div class="text-dark">
            <table class="w-100 table" v-if="productStockList.length > 0">
                <thead>
                    <th class="text-green-1"  v-for="title of Object.keys(productStockList[0])" :key="title.id">{{title}}</th>
                </thead>
                <tbody>
                    <tr v-for="rowProd of productStockList" :key="'row'+rowProd.id">  
                        <td class="text-dark" v-for="(item, i) of Object.values(rowProd)" :key="'td'+rowProd.id+'c'+i">  
                            <span v-if="i ===0" class="text-dark">{{item}}</span>

                            <input readonly v-else class="form-control text-dark fw-bold bg-white" v-model="rowProd[Object.keys(productStockList[0])[i]]"
                            type="text">
                        </td>                                                          
                    </tr>
                </tbody>
            </table>
            <hr>
        </div>
    </b-modal>

    <!-- DETAILS -->
    <div class="row mt-2 mx-1 " 
         style="min-height:75vh; background:rgba(0,0,0,0.5);">
        
        <div class="col">            
            <b-table
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(acciones)="data">
                    <div>                        
                        <button @click="showDetailsModal=true; selectedItem=data.item" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-file"></i></button>                        
                        <button @click="showDetailsModalDetails=true; selectedItemDetails=data.item.purchaseDetails" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-file"></i></button>
                    </div>
                </template>

            </b-table>

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[],
        fields: [
          { key: "id", label:"Código"},
          { key: "invoiceDate", label:"Fecha"},
          { key: "provider", label:"Proveedor"},
          { key: "acciones", label:"Detalles", tdClass:"text-center", thClass:"text-center"},          
        ],
        perPage: 10,
        currentPage: 1,

        newItem:{},
        showNewModal:false,
        selectedItem:{},
        showDetailsModal:false,
        table: 'purchases',

        selectedItemDetails: [],
        showDetailsModalDetails: false,
        productStockList:[],
        showProductsModal: false
    }
},
async mounted(){
    await this.getData()
},
methods:{
    async gotoProductStockModal(item){
        try {
            this.productStockList= [
            ]
            let res = await this.$store.dispatch('get', {path: 'productStock/getAllProductStockByPurchaseDetailId/'+ item.id});
            //console.log(res)
            if (res.length >0){
                  this.productStockList = res
                this.showProductsModal = true
                console.log('-------------->',this.productStockList)
            }
       
        } catch (error) {
            this.productStockList = []
            console.error('error', error)
        }
    },       
    async getData(){
        try {
            this.items= [
                // {id:"A84318", date:"2021-11-20", provider:"Cofiño Stal", products:[{id:1,name:"Motocicleta",chasis:"a123", plate:"a5465af8"}]  },
                // {id:"A84319", date:"2021-11-20", provider:"Cofiño Stal", products:[{id:1,name:"Motocicleta",svn:"a123", plate:"a5465af8", rin:14}]  },
                // {id:"A84320", date:"2021-11-20", provider:"Cofiño Stal", products:[{id:1,name:"Motocicleta",chasis:"a123", plate:"a5465af8"}]  },
            ]
            let res = await this.$store.dispatch('get', {path: this.table + '/getAll'});
            //console.log(res)
            if (res.length >0){
                this.items = res
            }
       
        } catch (error) {
            this.items = []
            console.error('error', error)
        }
    },
    async postData(){
        try {            
            this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})            
        } catch (error) {
            
        }        
    },    
    async deleteItem(item){
        const {isConfirmed} = await this.$swal({
            title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
            icon:"question",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            reverseButtons: true

        })
        if(isConfirmed){
            try {                
                this.$swal({title:"Aviso", text:"Ingresado" , icon:"success"})                
            } 
            catch (error) {
                console.error('error--->', error)
                this.$swal({title:"Aviso", text:"Error: " + error , icon:"error"})
            }                    
        }
    }
}
}
</script>

<style lang="scss" scoped>
td{color:#111 }
</style>